<section>
    <nav class="navbar navbar-expand-lg navbar-dark bg-green-hub-fbeeper px-2 py-1">
        <div class="container-fluid">
            <a class="navbar-brand logo_class py-0 pe-1" role="button" routerLink="/">
                <img src="assets/images/Wajeez-icon-white.svg" alt="Wajeez" width="90px" height="40px">
                <p class="text-center mb-0"><b>{{currentUserLocation?.country_name}}</b></p>
            </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item d-flex align-items-center justify-content-center py-1 py-md-0">
                <h5 class="text-white mb-0" style="font-weight: 600;">Hubs Monitor</h5>
              </li>
            </ul>
            <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item d-flex align-items-center justify-content-center px-3  py-1 py-md-0">
                    <h6 class="text-white mb-0" style="font-weight: 600;">{{currentAgent?.profile?.name}}</h6>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><span class="dropdown-item" role="button" (click)="confirmationModal = true">Se déconnecter</span></li>
                    </ul>
                </li>
            </ul>
          </div>
        </div>
    </nav>
</section>

<section class="container-fluid">
    <p-toast key="hubsMonitor" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <div class="row mt-5" *ngIf="isFirebaseConnected === false">
        <div class="col-12">
            <p-message class="w-100" severity="error" text="Vous êtes hors connexion!"></p-message>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <p-progressBar mode="indeterminate" *ngIf="loader || (loader_hubs != 100)"></p-progressBar>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p-table #hubsManagerT [value]="hubsList" responsiveLayout="stack" [responsive]="true" [rowHover]="true" [loading]="loading">
                <ng-template pTemplate="caption">
                    <div class="row">
                        <div class="col-12 col-md-9 d-flex align-items-center" style="flex-wrap: wrap;">
                            <!-- <span class="me-2">SMS:</span> -->

                            <!-- <div class="dropdown me-2">
                                <a class="btn btn-secondary dropdown-toggle" [ngClass]="(SMSSendReceivedSMS || {} | keyvalue).length > 0? 'sms_retour':''" style="box-shadow: none; font-weight: 600;"
                                    href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                  SMS
                                </a>

                                <div class="dropdown-menu sms_dropdown" aria-labelledby="dropdownMenuLink">
                                    <table class="w-100" style="font-weight: 500; border-bottom: 1px solid #e9ecef;" 
                                    *ngFor="let msg of SMSSendReceivedSMS | keyvalue:otherService.onCompare">
                                        <tbody>
                                          <tr>
                                            <td colspan="2" class="text-end py-1"><small>{{msg?.value?.timestamp | date: 'dd/MM/yyyy'}} {{msg?.value?.timestamp | date: 'HH:mm'}}</small></td>
                                          </tr>
                                          <tr>
                                            <td class="py-1"><div class="justify-content-start"><b>{{msg?.value?.client_name}}</b></div></td>
                                            <td class="text-end py-1">{{msg?.value?.from}}</td>
                                          </tr>
                                          <tr>
                                            <td colspan="2" class="py-1"> <div class="text-break"><b>msg: </b> {{msg?.value?.text}}</div></td>
                                          </tr>
                                          <tr>
                                            <td colspan="2" class="justify-content-start text-break py-1"><b>commande: </b> {{msg?.value?.last_order}}</td>
                                          </tr>
                                          <tr>
                                            <td colspan="2" class="text-end pb-2">
                                              <button class="btn btn-danger" (click)="removeUnknownSMS(msg?.key)">Fermer</button>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                    <ul *ngIf="(SMSSendReceivedSMS || {} | keyvalue).length == 0">
                                        <li class="dropdown-item text-center">Aucun message.</li>
                                    </ul>
                                </div>
                            </div>
                            <div *ngFor="let status of SMSSendStatus">
                                <div class="status_div" [ngStyle]="{'background-color': status.color, 'border-color': status.online? 'green' : 'red'}" [title]="status?.number + ' ' + ( status?.timestamp? '| '+ status?.timestamp : '') + ' ' + (status?. error_text? '| ' + status?. error_text : '') "></div>
                            </div> -->
                        </div>
                        <div class="col-12 col-md-3 d-flex justify-content-center justify-content-md-end align-items-center">
                            <button class="btn btn-success-fbeeper rounded-pill ms-3" style="font-weight: 600; padding: 9px, 32px, 9px, 32px;" (click)="initHubLoginForm(); hubLoginModal = true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                                </svg>
                                <span class="ps-1"> Ajouter un hub </span>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="profile.hub.name">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Hub
                                <p-sortIcon field="profile.hub.name"></p-sortIcon>
                            </div>
                        </th>
                        <th class=" text-md-center">Temps moy de livraison</th>
                        <th class=" text-md-center"
                            *ngIf="currentAgent?.profile?.permissions?.fleet_manager">
                            Livraisons
                        </th>
                        <th class=" text-md-center">Zones</th>
                        <th class=" text-md-center">Livraisons en cours</th>
                        <th class=" text-md-center">Livraisons en attente</th>
                        <th class=" text-md-center">Livreurs en service</th>
                        <th class=" text-md-center" 
                            *ngIf="!currentAgent?.profile?.permissions?.fleet_manager">
                            Etablissements connectées
                        </th>
                        <th class=" text-md-center" 
                            *ngIf="currentAgent?.profile?.permissions?.fleet_manager">
                            Crédit des livreurs
                        </th>
                        <th class=" text-md-center" 
                            *ngIf="currentAgent?.profile?.permissions?.fleet_manager">
                            Crédit des établissements
                        </th>
                        <th class=" text-md-center" >
                            <!-- *ngIf="currentAgent?.profile?.permissions?.can_create_delivery_transactions" > -->
                            incidents clients
                        </th>
                        <!-- <th class=" text-md-center">Actions</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" styleClass="p-datatable-tbody" let-hub>
                    <tr style="cursor: pointer;">
                        <td [style]="{'border-left': hub?.status?.hub_manager?.can_deliver == 0 ? '5px solid red' 
                                                    : (hub?.status?.hub_manager?.accept_delivery) == 0 ? '5px solid orange' 
                                                    : ''
                                    }">
                            <!-- (click)="onRowClick(hub, 1)"> -->
                            <span class="p-column-title">Hub</span>
                            <!-- <button type="button" class="btn btn-danger p-1 me-2" title="Déconnecter" (click)="selectedHub = hub; confirmationHubLogoutModal = true;">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-power" viewBox="0 0 16 16">
                                        <path d="M7.5 1v7h1V1z"/>
                                        <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812"/>
                                    </svg>
                            </button> -->
                            <!-- <span style="font-weight: 900; cursor: pointer;" title="Déconnecter" (click)="selectedHub = hub; confirmationHubLogoutModal = true;">
                                
                            </span> -->
                            <span style="font-size: 20px;" 
                                [ngStyle]="{'color':hub?.status?.hub_manager?.can_deliver == 0 ? 'red' : ''}"
                                (click)="onRowClick(hub, 1)">
                                {{hub?.profile?.hub?.name}}
                            </span>
                            <span *ngIf="hub?.status?.hub_manager?.accept_delivery == 0 && hub?.status?.hub_manager?.can_deliver == 1 " style="color: orange; line-height: 5px;">
                                En Pause
                            </span>
                        </td>

                        <td class="text-md-center" (click)="onRowClick(hub, 8)">
                            <span class="p-column-title">Temps moy de livraison</span>
                            <span style="color: green;" 
                                [ngStyle]="{'color': ((+hub?.status?.hub_manager?.avg_delivery_time) > 1800 
                                                    && (+hub?.status?.hub_manager?.avg_delivery_time) < 2700) ? 'orange' 
                                                    : ((+hub?.status?.hub_manager?.avg_delivery_time) > 1800 ? 'red' 
                                                    : 'green')}">
                                {{otherService.avgDeliveryTime(+hub?.status?.hub_manager?.avg_delivery_time * 1000) || "00m"}}
                            </span>
                        </td>

                        <td class="text-md-center" 
                            (click)="onRowClick(hub, 7)"
                            *ngIf="currentAgent?.profile?.permissions?.fleet_manager">
                            <span class="p-column-title">Livraisons</span>
                            <span style="color: inherit;" title="Nombre de livraisons">
                                {{hub?.status?.hub_manager?.fleet_stats?.deliveries}}
                            </span>
                            |
                            <span style="color: orange;" title="Livré après 10 minutes">
                                {{hub?.status?.hub_manager?.fleet_stats?.deliveries_yellow}}%
                            </span>
                            |
                            <span style="color: red;" title="Livré après 20 minutes">
                                {{hub?.status?.hub_manager?.fleet_stats?.deliveries_red}}%
                            </span>
                            
                        </td>


                        <td class="text-md-center" 
                            (click)="onRowClick(hub, 4)"
                            [style]="{'background-color':(hub?.status?.zones?.active_Zones_count && (hub?.status?.zones?.active_Zones_count < hub?.status?.zones?.all_zones_count))?'orange':''}"
                            >
                            <span class="p-column-title">Zones</span>
                            <span [style.color]="hub?.status?.zones?.active_Zones_count == hub?.status?.zones?.all_zones_count ? 'green' : ''"> {{(hub?.status?.zones?.active_Zones_count || 0) + "/" + (hub?.status?.zones?.all_zones_count || 0)}} </span>
                        </td>

                        <td class="text-md-center" (click)="onRowClick(hub, 1)">
                            <span class="p-column-title">Livraison en cours</span>
                            {{hub?.active_deliveries_accepted + hub?.active_errands_accepted}}
                            <!-- {{hub?.active_errands_accepted}} -->
                        </td>

                        <td class="text-md-center" 
                            [style]="{'background-color': (hub?.active_deliveries_pending + hub?.active_errands_pending) > 0 ? 'orange' : ''}"
                            [ngClass]="{'pendingIndication' : (hub?.active_deliveries_urgent) == true}"
                            (click)="onRowClick(hub, 1)">
                            <span class="p-column-title">Livraison en attente</span>
                            <!-- {{(hub?.active_deliveries?.pending | keyvalue)?.length || 0}} -->
                            {{hub?.active_deliveries_pending + hub?.active_errands_pending}}
                        </td>

                        <td class="text-md-center" 
                            (click)="onRowClick(hub, 5)"
                            [style]="{'background-color': (+hub?.status?.hub_manager?.fleet_stats?.available_staff) < (+hub?.status?.hub_manager?.fleet_stats?.working_staff) ? 'orange' : ''}">
                            <span class="p-column-title">Livreurs en service</span>
                            {{ (hub?.status?.hub_manager?.fleet_stats?.available_staff || 0) + "/" + (hub?.status?.hub_manager?.fleet_stats?.working_staff || 0)}}
                        </td>

                        <td class="text-md-center" 
                            *ngIf="currentAgent?.profile?.permissions?.fleet_manager"
                            (click)="onRowClick(hub, 2)">
                            <span class="p-column-title">Crédit des livreurs</span>
                            <span style="color: inherit;" 
                                [ngStyle]="{'color': ((+hub?.status?.hub_manager?.fleet_stats?.yellow_staff) > 1) ? 'orange' 
                                                    : ((+hub?.status?.hub_manager?.fleet_stats?.red_staff) > 1 ? 'red' 
                                                    : 'inherit')}">
                                <!-- [title]="((+hub?.status?.hub_manager?.fleet_stats?.yellow_staff) > 1) ? 'orange' 
                                            : ((+hub?.status?.hub_manager?.fleet_stats?.red_staff) > 1 ? 'red' 
                                            : 'inherit')" -->
                                {{hub?.status?.hub_manager?.fleet_stats?.staff_money 
                                    | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                            </span>
                        </td>

                        <td class="text-md-center" 
                            *ngIf="currentAgent?.profile?.permissions?.fleet_manager"
                            (click)="onRowClick(hub, 9)">
                            <span class="p-column-title">Crédit des établissements</span>
                            <span style="color: inherit;" 
                                [ngStyle]="{'color': ((+hub?.status?.hub_manager?.fleet_stats?.yellow_stores) > 1) ? 'orange' 
                                                    : ((+hub?.status?.hub_manager?.fleet_stats?.red_stores) > 1 ? 'red' 
                                                    : 'inherit')}"
                                [title]="((+hub?.status?.hub_manager?.fleet_stats?.yellow_stores) > 1) ? 'orange' 
                                            : ((+hub?.status?.hub_manager?.fleet_stats?.red_stores) > 1 ? 'red' 
                                            : 'inherit')">
                                {{hub?.status?.hub_manager?.fleet_stats?.stores_money 
                                    | currency: currentUserLocation?.lang?.currency_symbol:'symbol':currentUserLocation?.lang?.format: currentUserLocation?.lang?.code}}
                            </span>
                        </td>

                        <td class="text-md-center"
                            style="cursor: default;"
                            *ngIf="!currentAgent?.profile?.permissions?.fleet_manager">
                            <span class="p-column-title">Etablissements connectées</span>
                            {{ ((hub?.stores_online | keyvalue)?.length || 0) + "/" + ((hub?.status?.stores | keyvalue)?.length || 0)}}
                        </td>

                        <td class="text-md-center"
                            (click)="onRowClick(hub, 6)"
                            [style]="{'color': currentAgent?.profile?.permissions?.fleet_manager? 'var(--info-hub-fbeeper)': 'orange'}">
                            <span class="p-column-title">Client tickets</span>
                            {{currentAgent?.profile?.permissions?.fleet_manager? hub?.status?.hub_manager?.fleet_client_tickets : hub?.status?.hub_manager?.hot_client_tickets}}
                        </td>

                        <!-- <td class="text-md-center" style="cursor: default;">
                            <span class="p-column-title">Actions</span>
                            <span style="font-weight: 900; cursor: pointer;" title="Déconnecter" (click)="selectedHub = hub; confirmationHubLogoutModal = true;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E74645" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                                    <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                </svg>
                            </span>
                        </td> -->
                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="11" class="text-center">Aucun Hub connecté.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</section>

<!-- confirmation hub logout modal -->
<p-dialog header="Confirmation" [(visible)]="confirmationHubLogoutModal" [modal]="true" [style]="{width: '35vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true">
    <div class="row">
        <div class=" offset-md-1 offset-lg-1 col-sm-10 col-md-10 col-lg-10">
            <p class="mb-0">Êtes-vous sûr de vouloir déconnecter de <b> {{selectedHub?.profile?.hub?.name}} </b>?</p>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12 col-md-12 col-lg-12 text-end">
            <button class="btn btn-default-fbeeper me-1" (click)="confirmationHubLogoutModal = false; selectedHub = {}">Non</button>
            <button type="submit" class="btn btn-danger-hub-fbeeper fw-bold" (click)="hubLogout(selectedHub)">Oui</button>
        </div>
    </div>
</p-dialog>

<!-- confirmation modal -->
<p-dialog header="Confirmation" [(visible)]="confirmationModal" [modal]="true"  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true">
  <div class="row">
      <div class=" offset-md-1 offset-lg-1 col-sm-10 col-md-10 col-lg-10">
          <p class="mb-0">Êtes-vous sûr de vouloir vous déconnecter?</p>
      </div>
  </div>
  <div class="row mt-4">
      <div class="col-sm-12 col-md-12 col-lg-12 text-end">
          <button class="btn btn-default-fbeeper fw-bold mr-3" (click)="confirmationModal = false;">Non</button>
          <button type="submit" class="btn btn-danger fw-bold" (click)="logout()">Oui</button>
      </div>
  </div>
</p-dialog>

<!--  hub login modal -->
<p-dialog header=" " [(visible)]="hubLoginModal" [modal]="true" [dismissableMask]="true" styleClass="myClass">
    <p-toast key="KeyLogin" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <form class="container w-100" [formGroup]="hubLoginForm" (ngSubmit)="hubLogin(btnHubLogin)">
        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/images/logo-circle.png" alt="Wajeez logo" style="max-width: 90px; max-height: 90px;">
            </div>
        </div>
        <div class="form-group mt-2 text-center">
            <h4 class="font-fbeeper text-dark" style="font-weight: 500;">Bienvenu au<B style="color: var(--success-hub-fbeeper);"> Wajeez Hub </B></h4>
        </div>
        <div class="form-group mt-5 px-4" >
            <input type="username" class="form-control border-top-0 border-end-0 border-start-0" pInputText id="inputUsername" formControlName="username" placeholder="Nom d'utilisateur">
            <small class="form-text text-danger" *ngIf="hubLoginForm.controls.username.invalid && hubLoginForm.controls.username.touched && hubLoginForm.controls.username.errors['required']">Le nom d'utilisateur est obligatoire</small>
        </div>
        <div class="form-group mt-3 px-4">
            <input type="password" class="form-control border-top-0 border-end-0 border-start-0" pInputText id="inputPassword" formControlName="password" placeholder="Mot de passe" autocomplete="off">
            <small class="form-text text-danger" *ngIf="hubLoginForm.controls.password.invalid && hubLoginForm.controls.password.touched">Le mot de passe est obligatoire</small>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-end">
                <button class="btn btn-default-fbeeper me-1" (click)="hubLoginModal=false">Annuler</button>
                <button class="btn btn-success-fbeeper" style="min-width: 100px;" type="submit" #btnHubLogin>
                    <div *ngIf="btnHubLogin.disabled && hubLoginForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Se connecter
                </button>
            </div>
        </div>
    </form>
</p-dialog>