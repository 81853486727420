import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from './services/firebase.service';
import { GlobalInjectableService } from './services/global-injectable.service';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Wajeez Hub';
  showSplash: boolean = true;
  
  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    private globalInjectableService: GlobalInjectableService,
  ){
    registerLocaleData(localeFr, 'fr');
  }
//
  async ngOnInit() {
    //await this.getCurrentCountry();
    //console.log("app component");
    <any>window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token_auth_agent = localStorage.getItem('token_auth_agent');
        let sub_token_hub_agent = localStorage.getItem('sub_token_hub_agent');
        if((token_auth_agent == undefined) || (sub_token_hub_agent == undefined)) { // you can update this as per your key
            // DO LOGOUT FROM THIS TAB AS WELL
            //this.router.navigate(['/']); 
            this.router.navigateByUrl("/login", { skipLocationChange: true });
        }
      }
    }, false);

    this.firebaseService.getFirebaseConnection().valueChanges().subscribe(
      (data: boolean) => {
        this.globalInjectableService.changeIsFirebaseConnected(data)
      }
    );

    this.globalInjectableService.currentIsSplashScreen.subscribe(
      (data: any) => {
        //console.log('app component showSplash', data)
        this.showSplash = data;
      }
    )

  }

}
